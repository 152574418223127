<template>
    <div class="crop-management">
        <van-cell v-if="!isNoData">
            <div class="title-item">
                <span class="title">{{pests.title}}</span>
                <p>知识来源:百度百科</p>
            </div>
            <div class="content-item img-item">
                <img :src="pests.url"/>
            </div>
            <div class="content-item">
                <p>{{pests.content}}</p>
            </div> 
            <div class="content-item">
                <p class="title">防治方案:</p>
                <p>{{pests.plan}}</p>
            </div>
            <div class="item-divider"></div>
            <div class="expert-container">
                <div class="expert-item" v-for="(item, index) in pests.experts" :key="index">
                    <div class="expert-top">
                        <div class="left">
                            <div class="logo"></div>
                            <div>
                                <p>专家姓名:{{item.name}}</p>
                                <p>联系方式：{{changePhone(item.phone)}}</p>
                            </div>
                        </div>
                        <div class="right">
                            <a :href="'tel:'+ item.phone"></a>
                        </div>
                    </div>
                    <div class="expert-bottom">
                        <p v-if="item.name == '吴专家'">专家简介：1993年毕业于山东农业大学。一直在章丘区农业局工作。研究方向和专长为：植保和大葱种植。</p>
                        <p v-else>专家简介：1994年毕业于重庆大学。一直在涪陵农业局工作。研究方向和专长为：蚜虫和黄斑防治。</p>
                    </div>
                </div>
            </div>
        </van-cell>
        <van-cell v-else>
            <div class="no-data">
                <div>
                    <img src="../../assets/images/icon_nodata.png"/>
                </div>
                <div>
                    <span>暂无数据</span>
                </div>
            </div>
        </van-cell>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isNoData: false,
            pests: {
                title: '',
                url: ''
            },
            expertsArr: [],
        }
    },
    mounted() {
    },
    methods: {
        /** 手机号码中间四位*号显示 */
        changePhone(num) {
            let phone = String(num);
            let reg = /^(\d{3})\d{4}(\d{4})$/
            return phone.replace(reg, '$1****$2')
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.pests = to.params;
        });
    }
}
</script>

<style lang="scss" scoped>
    .crop-management {
        padding-top: 18px;
        background-color: #fff;
        .van-cell {
            width: 337px;
            min-height: 600px;
            margin: 0 auto;
            margin-bottom: 10px;
            padding: 0;
            border-radius: 8px;
            .title-item {
                .title {
                    font-size: 18px;
                    font-weight: 550;
                    color: #4F4F4F;
                }
                p {
                    font-size: 12px;
                    color: #7D7D7D;
                }
            }
            .img-item {
                margin: 26px 0;
            }
            .content-item {
                img {
                    width: 337px;
                    height: 200px;
                    margin: 0 auto;
                    border-radius: 4px;
                }
                p {
                    font-size: 14px;
                    color: #505050;
                }
                .title {
                    margin-top: 14px;
                    margin-bottom: 10px;
                    font-size: 16px;
                    font-weight: 550;
                }
            }
            .plan-item {
                margin-top: 16px;
                span {
                    margin-top: 0;
                    margin-right: 8px;
                } 
                p {
                    color: #7D7D7D;
                }
                .phone {
                    color: #438EFD;
                }
            }
            .item-divider {
                width: 337px;
                height: 21px;
                margin: 0 auto;
                margin-top: 44px;
                margin-bottom: 21px;
                background: url(../../assets/images/home/slogan.png) no-repeat;
                background-size: contain;
            }
            .expert-container {
                .expert-item {
                    margin-bottom: 8px;
                    padding: 24px 24px 17px 20px;
                    border-radius: 4px;
                    border: 1px solid #D4D4D4;  
                    .expert-top {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .left {
                            display: inline-flex;
                            align-items: center;
                            .logo {
                                width: 47px;
                                height: 47px;
                                margin-right: 16px;
                                background: url(../../assets/images/home/expert.png) no-repeat;
                                background-size: contain;
                            }
                            p {
                                font-size: 14px;
                                color: #505050;
                            }
                        }
                        .right {
                            a {
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                background: url('../../assets/images/addressBook/_icon_contact list_phone.png') no-repeat;
                                background-size: contain;
                            }
                        }
                    }
                    .expert-bottom {
                        margin-top: 20px;
                        p {
                            line-height: 17px;
                            font-size: 12px;
                            color: #898989;
                        }
                    }
                }
            }
            .no-data {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
            }
        }
    }
     
</style>